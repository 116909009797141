import { default as _91article_93QYQgGVQN4jMeta } from "/app/app/pages/discover/[category]/[subcategory]/[article].vue?macro=true";
import { default as indexCFmBM2I6NQMeta } from "/app/app/pages/discover/[category]/[subcategory]/index.vue?macro=true";
import { default as index3SVhwrpbqxMeta } from "/app/app/pages/discover/[category]/index.vue?macro=true";
import { default as glossaryMmBIrmRPmFMeta } from "/app/app/pages/glossary.vue?macro=true";
import { default as indexM6mJRhIbwTMeta } from "/app/app/pages/index.vue?macro=true";
import { default as _91article_93nIQjLljvCMMeta } from "/app/layers/news/app/pages/news/[subcategory]/[article].vue?macro=true";
import { default as indexiQWLozPVpiMeta } from "/app/layers/news/app/pages/news/[subcategory]/index.vue?macro=true";
import { default as indexGXPi9SfDBeMeta } from "/app/layers/news/app/pages/news/index.vue?macro=true";
import { default as _91article_93lGV1eRhWXfMeta } from "/app/layers/pro/app/pages/pro/discover/[category]/[article].vue?macro=true";
import { default as indexX7cagupqRiMeta } from "/app/layers/pro/app/pages/pro/discover/[category]/index.vue?macro=true";
import { default as indexnNrXFIkYruMeta } from "/app/layers/pro/app/pages/pro/index.vue?macro=true";
export default [
  {
    name: "discover-category-subcategory-article",
    path: "/discover/:category()/:subcategory()/:article()",
    component: () => import("/app/app/pages/discover/[category]/[subcategory]/[article].vue").then(m => m.default || m)
  },
  {
    name: "discover-category-subcategory",
    path: "/discover/:category()/:subcategory()",
    component: () => import("/app/app/pages/discover/[category]/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "discover-category",
    path: "/discover/:category()",
    component: () => import("/app/app/pages/discover/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "glossary",
    path: "/glossary",
    component: () => import("/app/app/pages/glossary.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news-subcategory-article",
    path: "/news/:subcategory()/:article()",
    component: () => import("/app/layers/news/app/pages/news/[subcategory]/[article].vue").then(m => m.default || m)
  },
  {
    name: "news-subcategory",
    path: "/news/:subcategory()",
    component: () => import("/app/layers/news/app/pages/news/[subcategory]/index.vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/app/layers/news/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "pro-discover-category-article",
    path: "/pro/discover/:category()/:article()",
    component: () => import("/app/layers/pro/app/pages/pro/discover/[category]/[article].vue").then(m => m.default || m)
  },
  {
    name: "pro-discover-category",
    path: "/pro/discover/:category()",
    component: () => import("/app/layers/pro/app/pages/pro/discover/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "pro",
    path: "/pro",
    component: () => import("/app/layers/pro/app/pages/pro/index.vue").then(m => m.default || m)
  }
]